

import React, { useState, useEffect } from "react";
import '../styles/packages.scss';
import checkIcon from "../images/check-icon.png";
import { TiTick } from "react-icons/ti";

export default function Packages() {
  const packages = [
    {
      heading: "Rapid MVP Development",
      points: [
        "Product Market Research",
        "MVP Roadmap",
        "UX/UI Design For MVP",
        "Agile Development",
        "Quality Check/QA",
        "Testing",
        "Deploy",
      ],
      benifits: [
        "Fully-Functional MVP",
        "Ready for Testing & Feedback",
        "Wireframes",
        "User Personas",
        "Interactive Prototypes",
      ],
    },
    {
      heading: "All-in-one Product Elevator",
      points: [
        "Product Market Research",
        "MVP Roadmap",
        "UX/UI Design For MVP",
        "Agile Development",
        "Quality Check/QA",
        "Testing",
        "Deploy",
        "User Acquisition (2 Channels)",
        "User Retention",
        "Monetization Strategies",
        "Product Analytics",
        "User Research",
        "A/B Testing",
        "CRO",
        "Brand Style Guide",
        "Logo",
        "Visual Assets (30)",
        "Responsive Website With SEO",
        "Product Launch Strategies",
      ],
      benifits: [
        "Google Accelerator",
        "Rapid MVP Development",
        "Marketing Automation",
      ],
    },
    {
      heading: "Growth Accelerator Program",
      points: [
        "User Acquisition (2 Channels)",
        "User Retention",
        "Monetization Strategies",
        "Product Analytics",
        "User Research",
        "A/B Testing",
        "CRO",
      ],
      benifits: [
        "Growth RoadMap",
        "Implementation of Growth Hacking",
        "Data-Driven Insight",
        "Performance Tracking",
        "Reporting",
      ],
    },
  ];

  return (
    <section id="packages" className="overflow-hidden pb-5 mb-0">
      <div className="container">
        <div className="d-flex flex-column gap-2 mb-lg-3 mb-md-2 mb-5">
          <h1 className="h2 fw-bold text-center text-uppercase text-gradient m-0">Packages</h1>
        </div>
        <div className="row m-0 gap-0">
          {packages.map((pkg, index) => {
            let aosEffect = "";

            if (index === 0) aosEffect = "fade-left";
            if (index === 1) aosEffect = "fade-center";
            if (index === 2) aosEffect = "fade-right";

            return (
              <div className="col-md-4" key={index}>
                <div className={`card border-3 shadow-0 overflow-hidden h-100 ${index === 1 ? "active" : ""}`} data-aos={aosEffect}>
                  <div className="card-header p-2 px-3">
                    <h3 className="card-title text-center fw-bold m-0 text-dark">{pkg.heading}</h3>
                  </div>
                  <div className="card-body p-2 px-3">
                    <ul className="d-flex flex-column gap-1 m-0">
                      {pkg.points.map((point, idx) => (
                        <li className="m-0 d-flex align-items-center gap-2" key={idx}>
                          <TiTick />
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-footer p-2 px-3 border-0">
                    <h4 className="card-title text-center m-0 text-dark">
                      Benefits
                    </h4>
                  </div>
                  <div className="card-body p-2 px-3 h-100">
                    <ul className="d-flex flex-column gap-1 m-0 benefits">
                      {pkg.benifits.map((benifit, idx) => (
                        <li key={idx} className="d-flex align-items-center gap-2">
                          <TiTick />
                          {benifit}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-footer p-2 px-3 border-0 text-center">
                    <button className="btn btn-primary btn-theme rounded-3 w-100">
                      CONSULT NOW
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section >
  );
}
