import React from "react";
import '../styles/portfolio.scss';
import brand1 from "../images/brands/backofficefx.png";
import brand2 from "../images/brands/jojointernational.png";
import brand3 from "../images/brands/milogistics.png";
import brand4 from "../images/brands/resumegory.png";
import brand5 from "../images/brands/roshan.png";
import brand6 from "../images/brands/innovationwavex.png";
import brand7 from "../images/brands/belovedbooth.png";

export default function Portfolio() {

    return (
        <section id="portfolio" className="overflow-hidden">
            <div className="container">
                <div className="d-flex flex-column gap-2 mb-lg-5 mb-md-4 mb-5">
                    <h1 className="h2 fw-bold text-center text-uppercase text-gradient m-0">portfolio</h1>
                </div>
                <div className="row m-0">
                    <div className="col-lg-6 col-md-5 px-md-2 p-0 mt-3" data-aos="fade-right">
                        <p className="d-flex align-items-start justify-content-center gap-md-2 gap-1 m-0">
                            <span className="quote">“</span>
                            <span className="text-md-start text-center">We probably saved one to twon years in terms of development time. I'm excited that we have a partner</span>
                            <span className="quote">”</span>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-7 mt-4 mt-md-0 p-md-2 p-0" data-aos="fade-left">
                        <figure className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-end">
                            <img src={brand1} alt="Brand" />
                            <img src={brand2} alt="Brand" />
                            <img src={brand3} alt="Brand" />
                            <img src={brand4} alt="Brand" />
                            <img src={brand5} alt="Brand" />
                            <img src={brand6} alt="Brand" />
                            <img src={brand7} alt="Brand" />
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    );
}