import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/caseStudy.scss';

export default function CaseStudy() {
    const data = [
        {
            type: "problem",
            text: 'Unable to find the right product-market fit that satisfy customer needs',
        },
        {
            type: "result",
            text: 'Low customer adoption, high churn rates, and difficulty in generating revenue'
        },
        {
            type: "problem",
            text: 'Difficulty in recruiting skilled product designers, developers, and managers',
        },
        {
            type: "result",
            text: 'Delay in product development, extending time-to-market and allowing competitors to gain an advantage'
        },
        {
            type: "problem",
            text: 'Having a hard time because want to add lots of features to the app, making it complicated',
        },
        {
            type: "result",
            text: 'User frustration, lower engagement, and ultimately, product failure'
        },
        {
            type: "problem",
            text: 'Struggling to create user-friendly interfaces that enhance the overall user experience',
        },
        {
            type: "result",
            text: 'App makes less money, fewer subscribers, the company struggles to survive'
        },
        {
            type: "problem",
            text: 'Facing competition from established players and other startups'
        },
        {
            type: "result",
            text: 'Customers may choose other options over your product, lead to lower profits or slower growth'
        },
    ];

    return (
        <section id="case-studies" className="overflow-hidden mb-0">
            <div className="container">
                <div className="d-flex flex-column gap-2 mb-lg-3 mb-md-2 mb-5">
                    <h1 className="h2 fw-bold text-center text-uppercase text-gradient m-0">Does this sound like your story?</h1>
                    <h2 className="h3 text-center m-0">Problem → Result</h2>
                </div>
                <div className="row m-0 gap-0">
                    {data.map((card, index) => (
                        <div className="col-md-6 p-0 px-lg-3 px-md-2 ps-lg-0 ps-md-0 d-flex flex-column gap-3 column mb-2" key={index}
                        data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}>
                            <div className={"card border-3 h-100 " + card.type}>
                                <div className="card-body">
                                    <p className='m-0'>{card.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}