import React from "react";
import '../styles/services.scss';
import { MdOutlineRocketLaunch, MdSupportAgent } from "react-icons/md";
import { TbDeviceAnalytics, TbDeviceDesktopCode, TbDeviceMobileCode } from "react-icons/tb";
import { AiOutlineCloudServer } from "react-icons/ai";
import { FaCode, FaLaravel, FaVuejs, FaNode, FaReact } from "react-icons/fa";
import { IoLogoElectron } from "react-icons/io5";


export default function DiscoverySession() {

    // Nav Link Scroll To Section
    let navLinkScrollToSection = (section: any) => {
        let element = document.getElementById(section);
        element?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <section id="services" className="overflow-hidden">
            <div className="container">
                <div className="d-flex flex-column gap-2 mb-lg-5 mb-md-4 mb-5">
                    <h1 className="h2 fw-bold text-center text-uppercase text-gradient m-0">Searching for Top Rated Product Development Team?</h1>
                    <p className="text-center m-0">Look no further! TechSynergySquad is your ultimate destination for turning your Web and App dreams into reality</p>
                </div>
                <div className="row m-0 gap-5 gap-md-0">
                    <div className="col-md-6 p-0 px-lg-5 px-md-4 ps-lg-0 ps-md-0 column-1" data-aos="fade-right">
                        <div className="d-flex flex-column gap-md-3 gap-2 w-100">
                            <div className="m-0 text-center text-md-start">
                                <span className="icon h1 p-4 rounded-circle m-0 d-inline-flex justify-content-center align-items-center"><MdOutlineRocketLaunch /></span>
                            </div>
                            <h2 className="h3 m-0 heading-color text-center text-md-start">Launching A New Product?</h2>
                            <p className="m-0 text-center text-md-start">We're your go-to team for crafting impactful products. Let's turn your vision into a reality that speaks volumes!</p>
                            <div className="d-flex flex-wrap justify-content-center justify-content-md-start gap-2">
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <TbDeviceAnalytics className="service_icon" />
                                    <span>MVP Development</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <AiOutlineCloudServer className="service_icon" />
                                    <span>SaaS Development</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <MdSupportAgent className="service_icon" />
                                    <span>Design Sprint</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <TbDeviceDesktopCode className="service_icon" />
                                    <span>Web App Development</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <TbDeviceMobileCode className="service_icon" />
                                    <span>Mobile App Development</span>
                                </small>
                            </div>
                            <div className="m-0 text-md-start text-center">
                                <span onClick={() =>navLinkScrollToSection("audit")} className="btn btn-primary btn-theme rounded-3">Launch Product</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 px-lg-5 px-md-4 pe-lg-0 pe-md-0 column-2" data-aos="fade-left">
                        <div className="d-flex flex-column gap-md-3 gap-2 w-100">
                            <div className="m-0 text-center text-md-start">
                                <span className="icon h1 p-4 rounded-circle m-0 d-inline-flex justify-content-center align-items-center"><FaCode /></span>
                            </div>
                            <h2 className="h3 m-0 heading-color text-center text-md-start">Stuck in the product dev hiring process?</h2>
                            <p className="m-0 text-center text-md-start">Behind on building your dream product team? We’re here to integrate our top-rated product dev team into your workforce</p>
                            <div className="d-flex flex-wrap justify-content-center justify-content-md-start gap-2">
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <FaLaravel className="service_icon" />
                                    <span>Laravel</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <FaVuejs className="service_icon" />
                                    <span>Vue.js</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <FaNode className="service_icon" />
                                    <span>Node.js</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <FaReact className="service_icon" />
                                    <span>React</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <TbDeviceMobileCode className="service_icon" />
                                    <span>Native</span>
                                </small>
                                <small className="service px-3 py-2 rounded-pill d-flex align-items-center gap-2">
                                    <IoLogoElectron className="service_icon" />
                                    <span>Electron</span>
                                </small>
                            </div>
                            <div className="m-0 text-md-start text-center">
                                <span onClick={() =>navLinkScrollToSection("audit")} className="btn btn-primary btn-theme rounded-3">Hire</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}