import React from "react";
import Hero from "../components/Hero";
import Services from "../components/Services";
import TSS from "../components/TSS";
import TSSBenifits from "../components/TSSBenifits";
import Portfolio from "../components/Portfolio";
import Audit from "../components/Audit";
import Packages from "../components/Packages";
import CaseStudy from "../components/CaseStudy";
import HowWeWork from "../components/HowWeWork";

export default function Home() {
    return (
        <>
            <Hero />
            <Services />
            <CaseStudy/>
            <HowWeWork/>
            <Packages />  
            {/* <TSS />
            <TSSBenifits /> */}
            <Portfolio />
            <Audit />
        </>
    );
}