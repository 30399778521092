
import React, { FormEvent, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import '../styles/audit.scss';
import { LuSend } from "react-icons/lu";
import SweetAlert2 from 'react-sweetalert2';

function Audit() {
  const [emailSentNotification, setEmailSentNotification] = useState({});
  let form = useRef<HTMLFormElement | null>(null);

  const BULLETS=[
    "In-depth analysis of current market trends",
    "Key insights into market demands and opportunities",
    "Feasibility analysis of your product idea",
    "Potential technical challenges and solutions",
    "Detailed UI/UX Audit",
    "Analysis of competitor strengths and weaknesses",
    "Actionable recommendations to enhance usability and engagement"
  ]
  let handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    if (form.current) {
      emailjs
        .sendForm(
          "service_hyo9ojf",
          "template_j5fp2w9",
          form.current,
          "LP_P89V7h3DHjX2JB"
        )
        .then(
          (response) => {
            setEmailSentNotification({
              show: true,
              icon: "success",
              title: "Message Sent!",
              text: "Thank you for your message. We will get back to you as soon as possible.",
              showConfirmButton: false,
              // timer: 5000,
              // timerProgressBar: true,
            });
          },
          (error) => {
            console.log(error.text);
          }
        );
    }

    e.currentTarget.reset();
  };

  return (
    <>
      <section id="audit" className="overflow-hidden">
        <div className="container">
          <div className="d-flex flex-column gap-2 mb-lg-5 mb-md-4 mb-4">
            <h1 className="h2 fw-bold text-center text-uppercase text-gradient audit-heading">
              Comprehensive SaaS App Audit & Idea Validation
            </h1>
          </div>
          <div className="row justify-content-center m-0 row-gap-4">
            <div className="col-md-6 audit-left-ctn" data-aos="fade-right">
              <div className="d-flex flex-column gap-3 align-items-center align-items-md-start">
                <h2 className="h3 heading-color m-0 audit-left-ctn__heading">
                    Unlock the Full Potential of Your SaaS Product with Our
                    Comprehensive Audit and Product Idea Validation (FREE)
                </h2>
                <ul className=" text-md-start bullets">
                    {BULLETS.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
              </div>
            </div>
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-md-5" data-aos="fade-left">
              <div className="card p-4 rounded-4">
                <form
                  ref={form}
                  onSubmit={handleFormSubmit}
                  className="d-flex flex-column gap-3"
                >
                  <div className="form-group">
                    <input
                      type="text"
                      name="from_name"
                      className="form-control p-2 px-3"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="user_email"
                      className="form-control p-2 px-3"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="user_email"
                      className="form-control p-2 px-3"
                      placeholder="Product Name or URL"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="user_email"
                      className="form-control p-2 px-3"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control p-2 px-3"
                      rows={5}
                      placeholder="Share any details"
                    ></textarea>
                  </div>
                  <div className="m-0 text-start">
                    <button
                      type="submit"
                      className="btn btn-outline-primary btn-outline-theme rounded-3 d-inline-flex justify-content-center align-items-center gap-2"
                    >
                      <span>Submit</span>
                      <LuSend />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SweetAlert2 {...emailSentNotification} />
    </>
  );
}

export default Audit;