import React, { useEffect, useState } from 'react';

// Router
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// Aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Loader from './components/Loader';
import ScrollTopBtn from './components/ScrollTopBtn';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';

import './styles/main.scss';


export default function App() {
  // Loader
  const [loading, setLoading] = useState<boolean>(true);

  // Aos
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
    });
    setTimeout(() => {
      setLoading(false);
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  // Routes
  const routes = [
    {
      path: '/',
      element: <Home />,
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <ScrollTopBtn />
          <NavBar />
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
}
