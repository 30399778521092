import React from "react";

import {
    BsFacebook,
    BsLinkedin,
    BsGoogle,
} from "react-icons/bs";

import "../styles/footer.scss";
import Logo from "./Logo";

export default function Footer() {

    let sections = [
        { "name": "Services", "link": "services" },
        { "name": "Case Studies", "link": "case-studies" },
        { "name": "How we work", "link": "how-we-work" },
        { "name": "Portfolio", "link": "portfolio" },
        { "name": "Audit", "link": "audit" }
    ];

    // Nav Link Scroll To Section
    let navLinkScrollToSection = (section: any) => {
        let element = document.getElementById(section);
        element?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="footer-wrapper">
            <footer className="px-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="brand">
                                <Logo classes="logo" />
                            </div>
                        </div>
                        <div className="col-md-4 mt-4 mt-md-0">
                            <h3 className="h5 heading-color mb-1 text-center text-md-start mb-3">Quick Links</h3>
                            <div className="d-flex flex-column align-items-center align-items-md-start gap-2">
                                {sections.map((section, index) => {
                                    return (
                                        <a href="https://techsynergysquad.com"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                navLinkScrollToSection(section.link);
                                            }} 
                                            className="link text-capitalize" key={index} aria-current="page">{section.name}</a>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-md-4 mt-4 mt-md-0">
                            <h3 className="h5 heading-color mb-1 text-center text-md-start mb-3">Follow Us On</h3>
                            <div className="d-flex justify-content-md-start justify-content-center align-items-center gap-2">
                                <a href="https://web.facebook.com/profile.php?id=61559602672088" target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-outline-theme social-icon facebook rounded-circle d-flex justify-content-center align-items-center"><BsFacebook /></a>
                                <a href="https://www.linkedin.com/company/techsynergysquad" target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-outline-theme social-icon linkedin rounded-circle d-flex justify-content-center align-items-center"><BsLinkedin /></a>
                                <a href="www.facebook.com" target="_blank" rel="noreferrer" className="btn btn-outline-primary btn-outline-theme social-icon twitter rounded-circle d-flex justify-content-center align-items-center"><BsGoogle /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bottom-line mt-3">
                <div className="container">
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center gap-2">
                        <p className="m-0">Made with ❤️ by Tech Synergy Squad</p>
                        <p className="m-0">© 2023 Tech Synergy Squad. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}