import { useEffect } from "react";
import "../styles/scrollTopBtn.scss";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function ScrollTopBtn() {
    useEffect(() => {
        const handleScroll = () => {
            const scrollTopBtn = document.querySelector<HTMLElement>("#scrollTopBtn");
            if (scrollTopBtn) {
                if (window.scrollY > window.innerHeight) {
                    scrollTopBtn.style.display = "block";
                } else {
                    scrollTopBtn.style.display = "none";
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClickScrollTopBtn = () => {
        window.scrollTo(0, 0);
    };

    return (
        <button className="btn btn-primary" id="scrollTopBtn" onClick={handleClickScrollTopBtn}>
            <MdOutlineKeyboardArrowUp />
        </button>
    );
}
