import React from 'react'
import lifecycle from '../images/lifeCyle.png'
import "../styles/howWeWork.scss"

export default function HowWeWork() {
    return (
        <section id="how-we-work" className="overflow-hidden">
            <div className="container">
                <div className="d-flex flex-column gap-2 mb-lg-3 mb-md-2 mb-5">
                    <h1 className="h2 fw-bold text-center text-uppercase text-gradient m-0">How we work?</h1>
                </div>
                <div className="d-flex justify-content-center">
                    <img src={lifecycle} alt='life-cycel' className='w-100 rounded-circle' data-aos="fade-up" />
                </div>
            </div>
        </section>
    );
}