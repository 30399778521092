import { useEffect, useState } from "react";
import { PopupButton } from "react-calendly";

export default function Calendly() {
    const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        const root = document.getElementById("root");
        if (root) {
            setRootElement(root);
        }
    }, []);

    return (
        <>
            {rootElement && (
                <PopupButton
                    url="https://calendly.com/alijawaidofficial-pk/tech-synergy-squad"
                    rootElement={rootElement}
                    text="Demo Call"
                    className="btn btn-outline-primary btn-outline-theme"
                />
            )}
        </>
    );
}
