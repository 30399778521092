import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/navBar.scss";
import Logo from "./Logo";
import Calendly from "./Calendly";


export default function NavBar() {

    let sections = [
        { "name": "Services", "link": "services" },
        { "name": "Case Studies", "link": "case-studies" },
        { "name": "How we work", "link": "how-we-work" },
        { "name": "Portfolio", "link": "portfolio" },
        { "name": "Audit", "link": "audit" }
    ];

    useEffect(() => {
        document.querySelectorAll("section").forEach((element, index) => {
            if (element) {
                let elementStartPosition = element.offsetTop - 100;
                let elementEndPosition = elementStartPosition + element.offsetHeight;

                if (window.scrollY >= elementStartPosition && window.scrollY < elementEndPosition) {
                    document.querySelectorAll(".scroll-link").forEach(link => link.classList.remove("active"));
                    let elementId = element.getAttribute('id');
                    let activeLink = document.querySelector('.scroll-link[data-link="' + elementId + '"]');
                    if (activeLink) activeLink.classList.add("active");
                }
            }
        });
    });

    // NavBar Sticky Effect
    let [lastScrollPosition, setScrollPosition] = useState(window.pageYOffset);
    window.addEventListener("scroll", () => {
        let navBar = document.querySelector(".nav-container");
        let currentScrollPosition = window.pageYOffset;

        if (lastScrollPosition > currentScrollPosition || window.scrollY < window.innerHeight) {
            navBar?.classList.remove("sticky");
        } else {
            navBar?.classList.add("sticky");
        }

        setScrollPosition(currentScrollPosition);
    });

    // Nav Link Scroll To Section
    let navLinkScrollToSection = (section: any) => {
        let element = document.getElementById(section);
        element?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="container nav-container">
            <div className="nav-wrapper">
                <nav id="navBar" className="d-flex navbar navbar-dark bg-dark navbar-expand-lg shadow">
                    <div className="d-flex align-items-center gap-sm-3 gap-1 order-1 navbar-brand-wrapper">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navBar" aria-controls="navBar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Link to="/" className="navbar-brand d-flex align-items-center position-relative m-0">
                            <Logo classes="logo position-absolute start-0 bottom-0" alt="Logo" />
                        </Link>
                    </div>
                    <div className="order-3 order-lg-2 collapse navbar-collapse order-2" id="navBar">
                        <div className="d-lg-flex justify-content-lg-between w-100">
                            <ul className="navbar-nav align-items-lg-center mx-lg-auto">
                                {sections.map((section, index) => {
                                    return (
                                        <li className="nav-item" key={index}>
                                            <span
                                                className="nav-link text-capitalize text-center scroll-link"
                                                onClick={() => navLinkScrollToSection(section.link)}
                                                data-link={`${section.link}`}
                                            >
                                                {section.name}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="m-0 order-2 order-lg-3 actions d-flex align-items-center justify-content-end">
                        <Calendly />
                    </div>
                </nav>
            </div>
        </div>
    );
}