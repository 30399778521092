import React from "react";
import '../styles/hero.scss';

// import { Typewriter } from 'react-simple-typewriter'
import brand1 from "../images/brands/backofficefx.png";
import brand2 from "../images/brands/jojointernational.png";
import brand3 from "../images/brands/milogistics.png";
import brand4 from "../images/brands/resumegory.png";
// import brand5 from "../images/brands/roshan.png";
// import brand6 from "../images/brands/innovationwavex.png";

export default function Hero() {

    // let heroWords = [
    //     'Product Dev Team',
    //     'Developers',
    //     'Product Designers'
    // ];
    
    // Nav Link Scroll To Section
    let navLinkScrollToSection = (section: any) => {
        let element = document.getElementById(section);
        element?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <section id="home" className="hero">
            <div className="bg"></div>
            <div className="container">
                <div className="d-flex flex-column justify-content-center align-items-center gap-md-4 gap-2 my-2">
                    <h1 className="h1 text-center m-0">
                        A Reliable Tech Partner for <span className="text-gradient">AI</span> & <span className="text-gradient">SaaS</span> Startups
                         {/* Highly Aligned &
                        Autonomous <span
                            className="text-gradient typed-text">
                            <Typewriter
                                words={heroWords}
                                typeSpeed={50}
                                deleteSpeed={50}
                                delaySpeed={1000}
                                cursor={false}
                                loop={true}
                            />
                        </span> */}
                    </h1>
                    <h2 className="h4 fw-normal text-center m-0">
                        We act as growth partners that can do anything from MVP development to marketing your Product so that it's ready to get [investors/money/funding].
                    </h2>
                    <div className="d-flex justify-content-center m-0 mt-2 mt-md-1 mb-2">
                        <button type="button" onClick={() => navLinkScrollToSection('audit')} className="btn btn-outline-primary btn-outline-theme">Let's Innovate together !</button>
                    </div>
                    <figure className="d-flex flex-wrap align-items-center justify-content-center">
                        <img src={brand1} className="brand-img" alt="Brand" />
                        <span className="divider"></span>
                        <img src={brand2} className="brand-img" alt="Brand" />
                        <span className="divider"></span>
                        <img src={brand3} className="brand-img" alt="Brand" />
                        <span className="divider"></span>
                        <img src={brand4} className="brand-img" alt="Brand" />
                    </figure>
                </div>
            </div>
        </section>
    );
}