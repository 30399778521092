import React from "react";
import loader from "../images/loader.gif";
import "../styles/loader.scss";

export default function Loader() {

    return (
        <div className="loader d-flex justify-content-center align-items-center w-100 vh-100 position-fixed top-0 start-0">
            <img src={loader} alt="loader" className="w-100" style={{ maxWidth: "250px" }} />
        </div>
    );
}