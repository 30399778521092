import React from "react";
import "../styles/main.scss";

export default function Logo(props: any) {
    return (
        <svg className={props.classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5049.05 1769.56">
            <defs>
                <linearGradient id="linear-gradient" x1="79.92" y1="116.3" x2="465.81" y2="502.2" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#00fbea" />
                    <stop offset="1" stopColor="#e917fb" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="229.23" y1="1102.93" x2="1726.41" y2="238.53" xlinkHref="#linear-gradient" />
                <linearGradient id="linear-gradient-3" x1="548.79" y1="1335.03" x2="1317.44" y2="3.7" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#00afea" />
                    <stop offset="0.54" stopColor="#6277ef" />
                    <stop offset="0.69" stopColor="#7c5aff" />
                    <stop offset="0.75" stopColor="#649aef" />
                    <stop offset="0.92" stopColor="#ff4ffb" />
                </linearGradient>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Logo">
                    <circle className="svg-logo-1" cx="272.87" cy="309.25" r="272.87" />
                    <path className="svg-logo-2" d="M1354.23,416.38s-113.19-4.05-299.14-4.05c-80.85,0-88.94,64.68-88.94,64.68l-3.93,780.31L554,1665.5V404.25C554,39.92,901.47,0,901.47,0h687.22Z" />
                    <path className="svg-logo-3" d="M749.59,1261.25l-1.73-848.92s0-218.29,226.38-217.42h504.7L1354.23,416.38l-299.14-4.05c-46.62.13-77.3,20.37-88.94,64.68l-3.93,780.31L749.59,1470Z" />
                    <text id="Text" className="svg-logo-4" transform="translate(1176.36 1092.76)">
                        ECH SYNE
                        <tspan className="svg-logo-5" x="2698.94" y="0">R</tspan>
                        <tspan x="3069.99" y="0">GY</tspan>
                        <tspan x="0" y="565.95">SQ</tspan>
                        <tspan className="svg-logo-6" x="766.73" y="565.95">U</tspan>
                        <tspan x="1139.83" y="565.95">AD</tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
